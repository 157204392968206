/**
 * ページ内リンクかどうかの判定
 * @module isInpageLink
 * @param {string} url 判定するURL文字列
 * @return {boolean} - 判定結果
 */
export var isInpageLink = function (url) {
    var pageUrl = location.href.match('.+/(.+?)([?#;].*)?$');
    var anchorUrl = url.match('.+/(.+?)([?#;].*)?$');
    var currentPageFileName = pageUrl ? pageUrl[1] : 'index';
    var anchorFileName = anchorUrl ? anchorUrl[1] : 'index';
    if (currentPageFileName === anchorFileName) {
        return true;
    }
    return false;
};
