/**
 * サイト内リンクかどうかの判定
 * @module isInternalLink
 * @param {string} url 判定するURL文字列
 * @return {boolean} - 判定結果
 */
export var isInternalLink = function (url) {
    if (typeof url !== 'string') {
        return false;
    }
    else if (url.indexOf(document.domain) !== -1) {
        return true;
    }
    else if (url.match(/^https?:\/\//)) {
        return false;
    }
    return true;
};
