var circle2path = /** @class */ (function () {
    function circle2path(hash) {
        this.cx = hash.cx;
        this.cy = hash.cy;
        this.r = hash.r;
    }
    circle2path.prototype.drawCircle = function (serector) {
        var target = document.querySelector(serector);
        var d_M = "M " + this.cx + " " + (this.cy - this.r);
        var d_A = "A " + this.r + " " + this.r + " 0 1 1 " + (this.cx - 1) + " " + (this.cy - this.r);
        target.setAttribute('d', d_M + " " + d_A + " Z");
    };
    circle2path.prototype.drawArc = function (serector, angle) {
        var target = document.querySelector(serector);
        var arc_x = this.cx + this.r * Math.cos((90 - angle) * (Math.PI / 180));
        var arc_y = this.cy - this.r * Math.sin((90 - angle) * (Math.PI / 180));
        var d_M = "M " + this.cx + " " + (this.cy - this.r);
        var d_A = "A " + this.r + " " + this.r + " 0 0 1 " + arc_x + " " + arc_y;
        target.setAttribute('d', d_M + " " + d_A);
        target.style.transformOrigin = 'center center';
    };
    return circle2path;
}());
export { circle2path };
