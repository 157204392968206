(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory();
	else if(typeof define === 'function' && define.amd)
		define([], factory);
	else if(typeof exports === 'object')
		exports["libs"] = factory();
	else
		root["libs"] = root["libs"] || {}, root["libs"]["init"] = factory();
})(window, function() {
return 