/**
 * htmlエンティティとURLエンコード
 * @module htmlSpecialChars
 * @param {string} string 変換する文字列
 * @return {string} - 変換した文字列
 */
export var htmlSpecialChars = function (string) {
    return encodeURI(string
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;')
        .replace(/'/g, '&#39;'));
};
